import { useState, useEffect } from "react"
import { useMediaQuery, Box, Button, Tabs, Tab } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import { Link, matchPath, useLocation } from "react-router-dom"
import ProfileIcon from "@mui/icons-material/PersonOutlineRounded"
import PaymentIcon from "@mui/icons-material/PaymentRounded"
import QueryIcon from "@mui/icons-material/ChatBubbleOutlineRounded"
import HomeIcon from "@mui/icons-material/HomeRounded"
import ClearIcon from "@mui/icons-material/ClearRounded"
import SettingsIcon from "@mui/icons-material/SettingsRounded"
import SampleIcon from "@mui/icons-material/InsertDriveFileRounded"
import disableScroll from "disable-scroll"

const NavBox = styled(({ className, expanded, ...props }) => (
  <Box
    component="nav"
    className={expanded ? `${className} expanded` : className}
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  top: 0,
  left: 0,
  height: "100vh",
  minWidth: "100%",
  backgroundColor: theme.palette.background.paper,
  boxSizing: "border-box",
  clipPath: "inset(0 0 0 100%)",
  zIndex: 500,
  transition: theme.transitions.create(["clip-path", "background-color"], {
    duration: theme.transitions.duration.navOut,
    easing: theme.transitions.easing.nav
  }),
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  "&.expanded": {
    clipPath: "inset(0)",
    transition: theme.transitions.create(["clip-path", "background-color"], {
      duration: theme.transitions.duration.navIn,
      easing: theme.transitions.easing.nav
    })
  },
  [theme.breakpoints.up("sm")]: {
    position: "sticky",
    minWidth: "230px",
    clipPath: "initial",
    paddingLeft: 0,
    paddingRight: 0
  }
}))

const NavButton = styled(props => (
  <Box component="button" {...props}>
    <ClearIcon fontSize="large" />
  </Box>
))(({ theme, expanded }) => ({
  backgroundColor: theme.palette.secondary.contrastText,
  color: theme.palette.secondary.dark,
  cursor: "pointer",
  borderRadius: "50%",
  display: "inline-block",
  position: "fixed",
  padding: theme.spacing(1),
  border: 0,
  fontSize: "0.1rem",
  WebkitTapHighlightColor: "transparent",
  right: "50px",
  bottom: "50px",
  zIndex: 750,
  transform: expanded ? "scale(1.15)" : "initial",
  transition: theme.transitions.create("transform", {
    duration: expanded ? 700 : 300
  }),
  "& .MuiSvgIcon-root": {
    transform: expanded ? "rotate(0deg)" : "rotate(45deg)",
    transition: theme.transitions.create("transform", {
      duration: expanded ? 700 : 300
    })
  },
  [theme.breakpoints.up("sm")]: {
    display: "none"
  }
}))

const SubNav = props => (
  <Tabs
    variant="scrollable"
    allowScrollButtonsMobile
    orientation="vertical"
    sx={{
      "& .MuiTabs-indicator": {
        backgroundColor: "secondary.main"
      },
      "& .MuiTabs-scrollButtons": {
        color: "secondary.main"
      }
    }}
    {...props}
  />
)

const SubNavLink = styled(props => (
  <Tab disableRipple to={props.value} component={Link} {...props} />
))(({ theme }) => ({
  color: theme.palette.grey[500],
  flexDirection: "row",
  justifyContent: "start",
  transition: theme.transitions.create("color", {
    duration: 300
  }),
  "&:hover": {
    color: theme.palette.grey[100]
  },
  "&.Mui-disabled": {
    color: theme.palette.grey[800]
  },
  "&.Mui-selected": {
    color: theme.palette.secondary.main
  },
  "& :first-of-type": {
    margin: `0px ${theme.spacing(2)} 0px 0px !important`,
    fontSize: 33
  }
}))

const ProfileImg = ({ src }) => (
  <Box
    component="img"
    src={src}
    referrerPolicy="no-referrer"
    sx={{ width: "35%", borderRadius: "50%", mb: 2 }}
  />
)

const LogOut = props => (
  <Button
    color="secondary"
    variant="outlined"
    sx={{ mb: { xs: 2, sm: 4 } }}
    {...props}
  >
    Log Out
  </Button>
)

const useRouteMatch = patterns => {
  const { pathname } = useLocation()

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}

export default function Nav({ steps, img, logout }) {
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.up("sm"))
  const routeMatch = useRouteMatch([
    "/settings",
    "/profile",
    { path: "/sample", end: false },
    "/payment",
    // { path: "/query", end: false },
    "/"
  ])
  const currentTab = routeMatch?.pattern?.path

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (expanded) {
      disableScroll.on(document.body)
    } else {
      disableScroll.off()
    }
  }, [expanded])

  const handleClick = () => {
    if (!isMedium) {
      setExpanded(!expanded)
    }
  }

  useEffect(() => {
    if (isMedium && expanded) {
      setExpanded(false)
    }
  }, [isMedium, expanded, setExpanded])

  return (
    <>
      <NavBox expanded={expanded ? 1 : 0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <ProfileImg src={img} />
          <LogOut onClick={logout} />
        </Box>
        {steps && (
          <SubNav value={currentTab}>
            <SubNavLink
              onClick={handleClick}
              icon={<HomeIcon />}
              label="Dashboard"
              value="/"
            />
            <SubNavLink
              onClick={handleClick}
              icon={<ProfileIcon />}
              label="Profile"
              value="/profile"
            />
            <SubNavLink
              onClick={handleClick}
              icon={<SampleIcon />}
              disabled={!steps.payment}
              label="Sample Papers"
              value="/sample"
            />
            {<SubNavLink
							onClick={handleClick}
							icon={<PaymentIcon />}
							disabled={!steps.profile}
							label="Payment"
							value="/payment"
						/>}
            {/* <SubNavLink
              onClick={handleClick}
              icon={<QueryIcon />}
              label="Query"
              value="/query"
            /> */}
            <SubNavLink
              onClick={handleClick}
              icon={<SettingsIcon />}
              label="Settings"
              value="/settings"
            />
          </SubNav>
        )}
      </NavBox>
      <NavButton expanded={expanded ? 1 : 0} onClick={handleClick} />
    </>
  )
}
